@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Manrope Regular';
  font-weight: 500;
  src: local('Manrope'), url(./fonts/Manrope/Manrope-VariableFont_wght.ttf) format('truetype');
}

@font-face {
    font-family: 'Manrope Bold';
    font-weight: 700;
    src: local('Manrope'), url(./fonts/Manrope/Manrope-VariableFont_wght.ttf) format('truetype');
}


* {
  font-family: 'Manrope Regular';
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

.text-red {
  color: var(--theme-primary-color)!important;
}

.border-red {
  border-color: var(--theme-primary-color)!important;
}

select {
   background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;  
  background: url('assets/mulle_down_icon.svg') no-repeat 100% 50%;
}

select::-ms-expand {
  display: none;
}

