@media (max-width: 576px) {
    .content {
      padding-top: 51px;
    }
  }
  
  @media (min-width: 577px) {
    .pt-scroll {
      padding-top: 51px;
    }
  
    .nav-sticky {
      position: fixed!important;
      min-width: 100%;
      top: 0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
      transition: all .25s ease-in;
      z-index: 1;
    }
  }

.button {
  width: 255px;
  height: 60px;
  box-shadow: 0px 5px 20px rgba(241, 91, 85, 0.7);
  border-radius: 43px;
}

  /* HAMBURGER MENU */

.hamburger {
    cursor: pointer;
    width: 48px;
    height: 48px;
    transition: all 0.25s;
  }
  
  .hamburger__top-bun,
  .hamburger__bottom-bun {
    content: '';
    position: absolute;
    width: 24px;
    height: 2px;
    background: #000;
    transform: rotate(0);
    transition: all 0.5s;
  }
  
  .hamburger:hover [class*="-bun"] {
    background: #333;
  }
  
  .hamburger__top-bun {
    transform: translateY(-5px);
  }
  
  .hamburger__bottom-bun {
    transform: translateY(3px);
  }
  
  .open {
    transform: rotate(90deg);
    transform: translateY(-1px);
  }
  
  .open .hamburger__top-bun {
    transform:
      rotate(45deg)
      translateY(0px);
  }
  
  .open .hamburger__bottom-bun {
    transform:
      rotate(-45deg)
      translateY(0px);
  }
  
  
.list-item-shadow {
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.09);
}

.dialog-shadow {
  box-shadow: 1px 1px 49px rgba(0, 0, 0, 0.13);
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #F15B55;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #F15B55;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #F15B55;
  }
}